import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import element2png from "../img/element2.png";

import pluselement from "../img/pluselement.png";

import playicon from "../img/playicon.png";

import ltbiglogo from "../img/ltbiglogo.png";
import smallxltm from "../img/smallxltm.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Section4.css";
import "../js/script";
import { blogContext } from "./Blogsandnews";
import moment from "moment";
import ReactGA from "react-ga4";

const Section4 = () => {
  const [visibleNews, setVisibleNews] = useState(4);
  const [newsdata, newsimages] = useContext(blogContext);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleExploreMore = () => {
    setVisibleNews((prev) => prev + 4);
    ReactGA.event({
      category: "Show More Content",
      action: "Click",
      label: "EXPLORE MORE",
      value: "EXPLORE MORE",
    });
  };

  const handleExploreLess = () => {
    setVisibleNews(4);
    ReactGA.event({
      category: "Show Less Content",
      action: "Click",
      label: "EXPLORE LESS",
      value: "EXPLORE LESS",
    });
  };
  const isExploreLessVisible = visibleNews >= newsdata?.length;


  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handleonclickGA = () => {
    handleScrollToTop()
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: "READ NEWS",
      value: "READ NEWS",
    });
  }

  return (
    <div class="main-body theme-orange dark-horizontal section4 overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Blogs & News | Mannai Travel" />

          {/* SECTION - 4  */}
          <div class="newspart">
            <div class="section section-item p0 ">
              <div class="container-fluid ">
                <div class="slide-body newsbg">
                  <div class="row  ">
                    <div class="col-lg-12">
                      <div class="col-md-12  newscontentpart ">
                        <h3
                          class="wow fadeInRight "
                          data-wow-delay="0.2s"
                          data-wow-duration="1s"
                        >
                          NEWS{" "}
                        </h3>
                        {/* <div class="newsbiglogo ">
                          {" "}
                          <img src={ltbiglogo} alt="" />
                        </div>
                        <div class="smallxnews  d-sm-block">
                          {" "}
                          <img
                            src={smallxltm}
                            alt=""
                            class="wow pulse"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                          />
                        </div> */}
                        <div class="playiconnews wow fadeInLeft">
                          {" "}
                          <img src={playicon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="newssectionpart">
                    {newsdata?.slice(0, visibleNews).reverse().map((e, i) =>
                      i === 0 || i % 2 === 0 ? (
                        <div class="row my-2" key={i}>
                          <div class="col-12 col-lg-6 newsdotsbgleft">
                            <div class="news-item ">
                              {/* news item photo  */}
                              <div
                                class="news-item-photo zoomingnews wow fadeInDown"
                                data-wow-delay=".2s"
                                data-wow-offset="150"
                              >
                                {" "}
                                {/* new image 1  */}
                                <img
                                  src={newsimages[i]}
                                  alt=""
                                  class="img-fluid  linearimg"
                                />
                                {/* ////////////////// */}
                                <div
                                  class="newsimgrbbtn wow fadeInUp"
                                  data-wow-delay="0.4s"
                                >
                                  {" "}
                                  <Link to={`/Blogsandnews/News/${e?.id}`}>
                                    <a
                                      class="btn btn-primary mr-4 fr "
                                      href="#"
                                      onClick={handleScrollToTop}
                                    >
                                      READ NEWS{" "}
                                      <i class="icon ion-ios-arrow-thin-right"></i>
                                    </a>{" "}
                                  </Link>
                                </div>
                              </div>
                              {/* image oda plus element  */}
                              <div class="blogimgtpluselement wow rotateIn">
                                {" "}
                                <img src={pluselement} alt="" />
                              </div>
                              {/* <div class="newsimgarelement ">
                                {" "}
                                <img
                                  src={element2png}
                                  alt=""
                                  class="ani-left-right"
                                />
                              </div> */}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mob-pad-20 tab-pad-20">
                            <div class="news-item nw1">
                              {/* plus  */}

                              <div className="d-flex align-items-center">
                                <div className="wow rotateIn">
                                  <img src={pluselement} alt="" />
                                </div>
                                <div style={{ marginLeft: "-50px" }}>
                                  <h5
                                    className="wow fadeInUp text-white fs24 pb-3 text-start"
                                    data-wow-delay="0.4s"
                                    data-wow-duration="1s"
                                  >
                                    {`News-${e?.id}`}
                                  </h5>
                                  <h6
                                    className="wow fadeInUp text-white fs20 mb-3 text-start"
                                    data-wow-delay="0.6s"
                                    data-wow-duration="1s"
                                  >
                                    {e?.title}
                                  </h6>
                                </div>
                              </div>

                              {/* news desc  */}
                              <div class="newstextright wow fadeInUp ps-md-5 ps-0">
                                <p className="fs-6 px-md-5 px-0 ">
                                {e?.description}
                                </p>
                              </div>
                              {/* Person (author) and timer  */}
                              <div class="ps-md-5 ps-0">
                                <div className="newstextrightbg wow fadeInUp  d-flex flex-column py-3 ps-md-4 ps-3 ms-md-5 ms-0 ">
                                  <div>
                                    <i class="icon ion-person"></i> {" "}
                                    {e?.authorname}
                                  </div>
                                  <div>
                                    <i class="icon ion-ios-timer-outline"></i>{" "}
                                    {moment(e?.updatedDate).format("LL")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="row mrsection " key={i}>
                          <div class="col-12 col-lg-6 mob-pad-20 ">
                            <div class="news-item nw2 ">
                              
                              <div className="d-flex align-items-center justify-content-end">
                                <div
                                  className="w-100"
                                  style={{ marginRight: "-50px" }}
                                >
                                  <h5
                                    class="wow fadeInUp text-white fs24 pb-3 text-end "
                                    data-wow-delay="0.4s"
                                    data-wow-duration="1s"
                                  >
                                    {`News-${e?.id}`}
                                  </h5>
                                  <h6
                                    class="wow fadeInUp text-white fs20 pb-3 text-end"
                                    data-wow-delay="0.6s"
                                    data-wow-duration="1s"
                                  >
                                    {e?.title}
                                  </h6>
                                </div>
                                <div className="">
                                  {" "}
                                  <img
                                    src={pluselement}
                                    alt=""
                                    className="wow rotateIn"
                                  />
                                </div>
                              </div>

                              <div class="newstextleft wow fadeInUp pe-md-5 pe-0">
                                <p className="px-md-5 px-0 fs-6">
                                {e?.description}
                                </p>
                              </div>
                              <div className="me-md-5 me-0">
                                <div class="newstextrightbg wow fadeInUp d-flex flex-column align-items-end py-3 pe-4 me-5">
                                  <div className="">
                                    <i class="icon ion-person"></i>{" "}
                                    {e?.authorname}
                                  </div>
                                  <div>
                                    <i class="icon ion-ios-timer-outline"></i>{" "}
                                    {moment(e?.updatedDate).format("LL")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* image and read news btn & plusimg */}
                          <div class="col-12 col-lg-6 newsdotsbgright mobnone">
                            <div class="news-item ">
                              <div
                                class="news-item-photo zoomingnews wow fadeInDown"
                                data-wow-delay=".2s"
                                data-wow-offset="150"
                              >
                                {" "}
                                <img
                                  src={newsimages[i]}
                                  alt=""
                                  class="img-fluid rightimg linearimg"
                                />
                                {/* pluse symbol with img  */}
                                <div class="blogimgtpluselement wow rotateIn">
                                  {" "}
                                  <img src={pluselement} alt="" />
                                </div>
                                {/* read news button  */}
                                <div
                                  class="newsimgrbbtn wow fadeInUp"
                                  data-wow-delay="0.4s"
                                >
                                  {" "}
                                  <Link to={`/Blogsandnews/News/${e?.id}`}>
                                    <a
                                      class="btn btn-primary mr-4 fr "
                                      href="#"
                                      onClick={() => handleonclickGA()}
                                    >
                                      READ NEWS{" "}
                                      <i class="icon ion-ios-arrow-thin-right"></i>
                                    </a>{" "}
                                  </Link>
                                </div>
                              </div>
                              <div class="blogimgtpluselement wow rotateIn">
                                {" "}
                                <img src={pluselement} alt="" />
                              </div>
                              {/* <div class="newsimgarelement ">
                                {" "}
                                <img
                                  src={element2png}
                                  alt=""
                                  class="ani-left-right"
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    <div class="text-center explorebtnnews  pb-4">

                      {isExploreLessVisible ? (
                        <button
                          className="btn btn-primary mr-4 explorelap"
                          onClick={handleExploreLess}
                        >
                          EXPLORE LESS{" "}
                          <i className="icon ion-ios-arrow-thin-left"></i>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary mr-4 explorelap"
                          onClick={handleExploreMore}
                        >
                          EXPLORE MORE{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Section4;
