import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import element2png from "../img/element2.png";
import pluselement from "../img/pluselement.png";
import playicon from "../img/playicon.png";

import ltbiglogo from "../img/ltbiglogo.png";
import smallxltm from "../img/smallxltm.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/SectionShape.css";
import "../js/script";
import Shape from "./Shape";
import { blogContext } from "./Blogsandnews";
import ReactGA from "react-ga4";

const SectionShape = () => {

  const [featureddata, featuredimg] = useContext(blogContext);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handleonclickGA = () => {
    handleScrollToTop()
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: "Read Blog",
      value: "Read Blog",
    });
  }

  return (
    <div className="main-body theme-orange dark-horizontal">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title="Blogs & News | Mannai Travel" />

          <div className="container-fluid ">
            <div className=" bnbg11">
              {/* <div className="bnbiglogoo  ">
                {" "}
                <img
                  src={ltbiglogo}
                  alt=""
                  className="img-fluid h-lg-100 h-md-50 h-sm-25"
                />
              </div> */}

              <div className="smallxbnn ">
                {" "}
                {/* <img
                  src={smallxltm}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                /> */}
                <div className="blogplay wow fadeInLeft justify-content-center  align-items-center gap-2">
                  <img src={playicon} alt="" className="" />
                  <h3
                    className="wow fadeInRight "
                    data-wow-delay="0.2s"
                    data-wow-duration="1s"
                  >
                    BLOGS
                  </h3>
                </div>
              </div>
              <div className="position-relative  blogpositionn ">
                <div className="roww d-flex align-items-center">

                  <img src={pluselement} alt="" />
                  <div className="row1 d-flex flex-column justify-content-start align-items-start">
                    <h5
                      className="wow fadeInUp fs28 lh-md"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      {`Blog-${featureddata?.id}`}
                    </h5>
                    <h6
                      className="wow fadeInUp fs28"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      {featureddata?.heading}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="blogbigimg1">
                <Shape />
                <div className="featcontt">
                  <h4 className="wow slideInLeft " data-wow-duration="1.2s">
                    {" "}
                    {featureddata?.title}
                  </h4>
                  <p
                    className="wow fadeInUp pt-0 pb-0"
                    data-wow-delay="0.6s"
                    data-wow-duration="1.4s"
                  >
                    {" "}
                    {featureddata?.subtitle}
                  </p>
                  <p
                    className="d-lg-flex d-none wow fadeInUp pt-0 mt-0 pb-4"
                    data-wow-delay="0.6s"
                    data-wow-duration="1.4s"
                  >
                    {" "}
                    {featureddata?.description}
                  </p>
                  <div
                    className=" wow fadeInUp d-flex ms-5"
                    data-wow-delay="0.4s"
                  ></div>
                  <div className="d-flex position-relative px-4">
                    <Link to={`/Blogsandnews/Blogs/${featureddata?.id}`}>
                      {/* <div className="mb-2">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right mb-1 ml-2"
                        />
                      </div> */}
                      <a
                        className="btn btn-primary  "
                        href="#"
                        onClick={() => handleonclickGA()}
                      >
                        READ BLOG{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionShape;
