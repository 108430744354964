import React from "react";
import amadues from "../img/Amadeus .png";
import travelport from "../img/Travelport-logo.png";
import element2png from "../img/element2.png";
import linkicon from "../img/linkicon.png";
import chart from "../img/image_2023_12_13T10_58_44_628Z.png";
import expense from "../img/expense.png";
import oracale from "../img/image_2023_12_13T10_59_13_411Z.png";
import zohobooks from "../img/zoho-expense-retina-logo.png";
import sap from "../img/sap-concur-logo.png";
import ReactGA from "react-ga4";

const Partneredtechnology = () => {

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Vertical Scroll",
      action: "Click",
      label: "EXPLORE MORE",
      value: "EXPLORE MORE"
    });
  };

  return (
    <div>
      <div className="p-5">
        <div className="row px-md-5 px-0 border-bottom-dash">
          <div className="col-md-8 mb-3">
            <h4
              className="border-bottom m-0 pb-2 text-white wow fadeInLeft"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              {" "}
              TECHNOLOGY. EMPOWERING INFORMED TRAVEL DECISIONS
            </h4>
            <p
              className="text-white  pt-2 fs-5  wow fadeInUp"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              To facilitate the varying end-goals and strategies with respect to
              travel needs, technology consultants at Mannai Travel help
              identify the latest and best tools that offer the ideal travel
              solutions for businesses.
            </p>
          </div>
          {/* <div className='col-md-7'></div> */}
        </div>
        <div className="row px-md-5 py-5 px-0">
          <div className="col-md-8">
            {/* <h4 className='border-bottom m-0 pb-2 text-white'> TECHNOLOGY. EMPOWERING INFORMED TRAVEL DECISIONS</h4> */}
            <p
              className="text-white pt-2 fs-5 wow fadeInLeft"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              A Global Distribution System (GDS) serves as a network for
              allowing service providers in the travel industry to carry out
              transactions. Mannai Travel, along with its GDS partners, allows
              businesses access to real-time information about hotel room
              availability, airline ticket availability, train tickets, cruise
              packages, and car rentals.
            </p>
            <div className="d-flex flex-column align-items-start mt-5">
              {/* <img
                src={element2png}
                alt=""
                className="ani-left-right mb-2 pl-2"
              /> */}
              <a
                className="btn btn-primary wow fadeInLeft learnmore fw-semibold"
                data-wow-delay="0.50s"
                data-wow-duration="1.2s"
                href="#"
                onClick={() => handleonclickGA()}
              >
                EXPLORE MORE <i className="icon ion-ios-arrow-thin-right"></i>
              </a>
            </div>
          </div>
          <div className="col-md-4 py-md-0 py-5 px-md-5 px-0  d-flex flex-column align-items-center justify-content-center">
            <img
              src={amadues}
              className="mb-3 wow slideInRight"
              width="250px"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
              style={{ backgroundColor: "#1b69bc" }}
            />
            <img
              src={travelport}
              className="bg-white wow slideInRight"
              width="250px"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            />
          </div>
        </div>
      </div>
      <div className="p-3 p-md-5 partnertechgradientbg">
        <div>
          <div className="px5">
            <h2
              className="text-white fw-bold wow slideInRight"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              ACCOUNT & EXPENSE MANAGEMENT
            </h2>
            <div className="row pt-5 align-items-center">
              <div className="col-md-7">
                <h4
                  className="border-bottom m-0 pb-2 text-white wow slideInRight"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                >
                  {" "}
                  EXPENSE MANAGEMENT
                </h4>
                <p
                  className="text-white fs-5  pt-2 wow fadeInUp"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                >
                  Mannai Travel offers exclusive services that ease the process
                  of optimizing expenses, consolidating billings, and managing
                  data. Through our expertise, businesses can manage processes
                  to get the most out of their annual travel budgets.
                </p>
              </div>
              <div className="col-md-5 px-5">
                <img
                  src={expense}
                  className="mb-3 p-5 wow slideInRight d-flex justify-content-center align-items-center"
                  width="250px"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                />
              </div>
            </div>
            <div className="row pb-5 align-items-center">
              <div className="col-md-5 px-5">
                <img
                  src={chart}
                  className="mb-3 p-5 wow slideInLeft d-flex justify-content-center align-items-center"
                  width="250px"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                />
              </div>
              <div className="col-md-7">
                <h4
                  className="border-bottom m-0 pb-2 text-white wow slideInRight"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                >
                  {" "}
                  EXPENSE MANAGEMENT TOOLS
                </h4>
                <p
                  className="text-white fs-5  pt-2 wow fadeInUp"
                  data-wow-delay="0.50s"
                  data-wow-duration="1.2s"
                >
                  Through the use of the latest technology in expense
                  management, Mannai Travel facilitates the creation,
                  submission, approval, reimbursement, and accounting of travel
                  expenses for businesses. These tools replace paperwork with an
                  efficient and automated workflow to upload, track, and submit
                  expense receipts conveniently.
                </p>
              </div>
            </div>
          </div>
          <div className="d-md-flex d-sm-grid gap-2 py-5 align-items-center align-items-stretch text-white">
            <div
              className=" darkblunbg1 p-5 d-flex flex-column justify-content-between mt-1 wow slideInLeft"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              {/* <div className='' > */}
              <img
                src={oracale}
                className="w-100 mb-3 partentechnologycardimhg object-fit-cover px-2"
              />
              <p className="text-align-justify pb-4 fw-normal fs-6">
                Oracle's Travel & Expense Management solution helps automate and
                streamline expense report approval and management, dramatically
                reducing the amount of time and effort required to submit,
                approve, process, and pay expense reports.
              </p>
              <div className="d-flex w-100 pt-5 justify-content-end">
                <div className="">
                  <p className="text-white text-center  mb-0 fw-normal">
                    MORE DETAILS
                  </p>
                  <hr className="text-white m-0 " />
                  <a
                    className="d-flex justify-content-center text-white text-decoration-none mt-1"
                    href="https://www.oracle.com/in/"
                    target="_blank"
                  >
                    Visit Website
                    <img src={linkicon} className="m-1" height={"15px"} />
                  </a>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              className="darkblunbg1 p-5 d-flex flex-column justify-content-between mt-1 wow slideInUp"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              {/* <div className='darkblunbg1 p-3 d-flex flex-column justify-content-between'> */}
              <img
                src={sap}
                className="w-100 mb-3 partentechnologycardimhg object-fit-contain"
              />
              <p className="text-align-justify pb-4 fw-normal fs-6">
                SAP Concur provides travel and expense management by automating
                spending processes of businesses. The high availability of SAP
                Concur helps efficient processing anywhere, anytime, and under
                any circumstances.
              </p>
              <div className="d-flex w-100 pt-5 justify-content-end">
                <div className="">
                  <p className="text-white text-center  mb-0 fw-normal">
                    MORE DETAILS
                  </p>
                  <hr className="text-white m-0 " />
                  <a
                    className="d-flex justify-content-center text-white text-decoration-none mt-1"
                    href="https://www.concur.co.in"
                    target="_blank"
                  >
                    Visit Website
                    <img src={linkicon} className="m-1" height={"15px"} />
                  </a>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              className="darkblunbg1 p-5 d-flex flex-column justify-content-between mt-1 wow slideInRight"
              data-wow-delay="0.50s"
              data-wow-duration="1.2s"
            >
              {/* <div className='darkblunbg1 p-3 d-flex flex-column justify-content-between'> */}
              <img
                src={zohobooks}
                className="w-100 mb-3 partentechnologycardimhg object-fit-contain"
              />
              <p className="text-align-justify pb-4 fw-normal fs-6">
                Zoho Expense has helped tens of thousands of businesses
                streamline corporate travel, automate expense reporting, and
                have complete control over spending, which helps get crucial
                financial insights.
              </p>
              <div className="d-flex w-100 pt-5 justify-content-end">
                <div className="">
                  <p className="text-white text-center  mb-0 fw-normal">
                    MORE DETAILS
                  </p>
                  <hr className="text-white m-0 " />
                  <a
                    className="d-flex justify-content-center text-white text-decoration-none mt-1"
                    href="https://www.zoho.com/in/expense/"
                    target="_blank"
                  >
                    Visit Website
                    <img src={linkicon} className="m-1" height={"15px"} />
                  </a>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partneredtechnology;
