//SCHEME without SSL certification
export const SCHEME = "https://";
//SCHEME with SSL certification
// export const SCHEME = "http://";

//DOMAIN for REMOTE_SERVER
// export const DOMAIN = "103.133.214.220";
//DOMAIN for localhost
// export const DOMAIN = "localhost:8080"
// export const DOMAIN = "localhost";
//DOMAIN for local IIS
// export const DOMAIN = "192.168.1.9";
export const DOMAIN = "cmservice.mannaitravel.com"

//PORT for REMOTE_SERVER
// export const PORT = ":5026";
//PORT for localhost
// export const PORT = ":8080";
//PORT for local IIS
// export const PORT = ":81"

export const PORT = ""