import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import logopng from "../img/logo.png";
import biglogopng from "../img/biglogobg.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
// import semicircledownelement from "../img/semicircledownelement.png";
// import semicircledownelement1 from "../img/semicircledownelement1.png";
// import semicircleelement from "../img/semicircleelement.png";
import uparrowelement from "../img/uparrowelement.png";
import water1element from "../img/water1element.png";
import waveselementdark from "../img/waveselementdark.png";
import waveliteelement2 from "../img/waveliteelement2.png";
import wavedark2 from "../img/wavedark2.png";
import smallcirclepng from "../img/smallcircle.png";
import leftarrowpng from "../img/leftarrow.png";
import serviceimg1 from "../img/serviceimg1.jpg";
import biglogoservicepng from "../img/biglogoservice.png";
import flydubai from "../img/FlydubaiAircraft.png";
import flykenya from "../img/KenyaAirways.png";
import flyphillipine from "../img/phillipineairlines.jpg";
import flyakasa from "../img/AKASAAir.jpeg";
import semicircledownelement from "../img/semicircledownelement.png";
import semicircleelement from "../img/semicircleelement.png";
import "./styles.css";
import "animsition";
import "animsition/dist/css/animsition.css";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../js/script";
import ReactGA from "react-ga4";

const StatsSection = ({ data, key }) => {
  console.log(data.bg);

  const handleonclickGA = (des) => {
    ReactGA.event({
      category: "Site Redirection",
      action: "Click",
      label: des?.title,
      value: des?.link,
    });
  };

  return (
    <div
      className="h-full section pp-scrollable slide slide-dark"
      id="ATF"
      key={key}
    >
      <div className="slide-container ">
        <div className="slide-bg" style={{ zIndex: 0 }}>
          <div
            className="inside opacityBG"
            style={{
              backgroundImage: `url(${data.title === "FLY DUBAI"
                  ? flydubai
                  : data.title === "KENYA"
                    ? flykenya
                    : data.title === "PHILLIPPINE"
                      ? flyphillipine
                      : flyakasa
                })`,
            }}
          ></div>
        </div>
        <div className="container ">
          {/* <div className="position-absolute stbiglogoservicepng">
            {" "}
            <img src={biglogoservicepng} alt="" />
          </div> */}

          {/* <div className="serviceelement1bot d-none d-md-block">
            {" "}
            <img
              src={element1png}
              className="wow pulse"
              data-wow-delay="300ms"
              data-wow-iteration="infinite"
              data-wow-duration="2s"
              alt=""
            />
          </div> */}
          {/* <div className="servciebiglogo-fixed">
          {" "}
          <img src={biglogopng} alt="" />
        </div> */}
          {/* <div className="semicircledownelement">
          {" "}
          <img src={semicircledownelement} alt="" />
        </div> */}
          {/* <div className="semicircleelement">
          {" "}
          <img src={semicircleelement} alt="" />
        </div> */}
          {/* <div className="stparentuparrowelement d-none d-md-block wow fadeInUp">
            {" "}
            <img src={roundtxtpng} alt="" className="wow rotateIn" />
            <img
              src={uparrowelement}
              alt=""
              class="ani-top-bottom stuparrowelement "
            />
          </div>
          <div className="water1element ani-move d-flex">
            {" "}
            <div className="semicircleelement">
              {" "}
              <img src={semicircleelement} alt="" />
            </div>
            <img src={water1element} alt="" />
          </div>
          <div className="waveselementdark d-flex ani-move">
            {" "}
            <div className="smallcircleelement">
              {" "}
              <img src={smallcirclepng} alt="" />
            </div>
            <div className="semicircledownelement">
              {" "}
              <img src={semicircledownelement} alt="" />
            </div>
            <img src={waveselementdark} className="" alt="" />
          </div> */}
          {/* <div class="stwater1element d-none d-lg-block">
          {" "}
          <img src={water1element} alt="" class="ani-move" />
        </div>
        <div class="stwaveselementdark d-none d-lg-block">
          {" "}
          <img src={waveselementdark} class="ani-move" alt="" />
        </div>
        <div class="smallcircleelement d-none d-md-block">
          {" "}
          <img src={smallcirclepng} alt="" />
        </div> */}
          <div className="stcontentpartservicesAT w-md-100 w-sm-100 w-75 fs-2">
            <div className="d-flex align-items-center">
              <div>
                <h1
                  className="wow slideInLeft sttitle text-white text-end"
                  data-wow-duration="1.2s"
                >
                  {data.title}
                </h1>
                <h1
                  className="wow slideInLeft sttitle text-white text-end"
                  data-wow-duration="1.2s"
                >
                  &nbsp;{data.title2}
                </h1>
              </div>
              <div className="plustitle-ml-60 wow rotateIn">
                {" "}
                <img src={pluselement} alt="" />
              </div>
            </div>
            <p
              className="normaltext  wow slideInRight SubHeading  mt-1"
              data-wow-delay=".1s"
              data-wow-duration=".80s"
            >
              {" "}
              {data.description}
            </p>
            <div className=" d-grid">
              {" "}
              {/* <img src={element2png} alt="" class="ani-left-right mb-2" /> */}
              <Link to={data.link} onClick={() => handleonclickGA(data)}>
                <a
                  className="btn btn-primary   "
                  href={data.link}
                  target="_blank"
                >
                  Visit {data.title} Website{" "}
                  <i className="icon ion-ios-arrow-thin-right"></i>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
