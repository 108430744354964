import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logopng from "../Pages/img/MannaiLogos/ManANI WHITE LOGO.png";
import logoSpaceTravel from '../Pages/img/MannaiLogos/MannaiLogos_SpaceTravel.png'
import logoSpaceCargo from '../Pages/img/MannaiLogos/MannaiLogos_SpaceCargo.png'
import biglogopng from "../Pages/img/biglogobg.png";
import homeicon from "../Pages/img/headerhome.png";
import facebook from "../Pages/img/mannaifacebook.png";
import instagram from "../Pages/img/mannaiinstagram.png";
import twitter from "../Pages/img/mannaitwitter.png";
import linkedin from "../Pages/img/mannailinkedin.png";
import snapchat from "../Pages/img/mannaisnapchat.png";
import youtube from "../Pages/img/mannaiyoutube2.png";
import tiktok from "../Pages/img/mannaitiktok.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/header.css";
import "../Pages/js/script";
import WhatsAppButton from "./WhatsAppButton";
import ReactGA from "react-ga4";
import ComponentServices from "./ComponentServices";
import moment from "moment";

const Header = ({ pageType }) => {
  const navigate = useNavigate();
  //required to dynamically change the copyright year without any manual intervention by the developer
  const date = new Date();
  const CRyear = moment(date).format("YYYY");

  const [isScrolled, setIsScrolled] = useState(false);

  const [menudat, setmenudat] = useState([]);
  const [menutoggles, setmenutoggles] = useState([]);

  const [socialmediadat, setsocialmediadat] = useState({});

  useEffect(() => {

    ComponentServices
      .getmenus()
      .then((ev) => {
        setmenudat(ev.data)
        const df = ev?.data.map((ev) => ev?.submenus.length > 0);
        setmenutoggles(df);
      })
      .catch((err) => console.error("Error fetching Menu Data: ", err));

    ComponentServices
      .getsocialmedia()
      .then((ef) => setsocialmediadat(ef?.data))
      .catch((err) => console.error("Error fetching Social Media Links: ", err));

  }, []);


  const handleMenuToggles = (index) => {
    const pres = menutoggles[index];
    const newtog = [...menutoggles];
    newtog[index] = pres === true ? false : true;

    setmenutoggles(newtog)
  }


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => { }, []);

  const toggleClassToHtml = () => {
    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    } else {
      htmlTag.classList.add("body-menu-opened");
      htmlTag.classList.remove("body-menu-close");
    }

    ReactGA.event({
      category: "SideBar Menu",
      action: "Click",
      label: "Hamburger Menu",
      value: "Hamburger Menu"
    });
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const handleScrollToDiv = () => {
    window.scrollTo({
      top: 800,
      behavior: "instant",
    });
    // const targetDiv = document.getElementById('luftansa');
    // if (targetDiv) {

    //   targetDiv.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //   });
    // }
  };

  const handleMainMenuGA = (des) => {
    handleScrollToTop();
    ReactGA.event({
      category: "SideBar Menu Selection",
      action: "Click",
      label: des,
      value: des,
    });
  };

  const handleHyperLinksGA = (des) => {
    handleScrollToTop();
    ReactGA.event({
      category: "SideBar HyperLink Selection",
      action: "Click",
      label: des,
      value: des,
    });
  };

  const handleSocialMediaGA = (des) => {
    ReactGA.event({
      category: "SideBar SocialMedia Selection",
      action: "Click",
      label: des,
      value: des,
    });
  }

  return (
    <header
      id="header"
      className={`header header-fixed ${isScrolled ? "scrolled" : ""}`}
    >
      <div className="container-fluid mp0 clearfix">
        <div className="position-relative">
          <div className="d-flex justify-content-between">
          {" "}
          <div className="p-2 px-2 py-2 px-lg-4 py-lg-4 px-xl-4 px-lg-5 px-md-4 py-md-4  ">
            {" "}
            <div to="/"
              onClick={() => handleMainMenuGA("HomePage")}
            >
              <div
                className={`logomannai p-md-1 p-2 ${isScrolled ? "d-none" : ""
                  }`}
              >
                {pageType === "SpaceTravel" ? 
                <img src={logoSpaceTravel} alt="" className="brand-logo img-fluid logoSpaceTravelImg" /> 
                :
                pageType === "SpaceCargo" ?
                <img src={logoSpaceCargo} alt="" className="brand-logo img-fluid logoSpaceCargoImg" /> 
                : 
                <img src={logopng} onClick={() => navigate('/')} alt="" className="brand-logo img-fluid logomannaiImg" />}</div>
            </div>
          </div>
          <WhatsAppButton />
          <div
            className="d-flex h-fit-content"
            style={{ background: "#17355d" }}
          >
            <div className="homeicon d-flex align-items-center mx-2">
              <Link to="/"
                onClick={() => handleMainMenuGA("HomePage")}
              >
                <img
                  src={homeicon}
                  height={30}
                  width={30}
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <button
              className="nav-toggle-btn a-nav-toggle"
              onClick={toggleClassToHtml}
            >
              {" "}
              <span className="nav-toggle nav-toggle-sm">
                {" "}
                <span className="stick stick-1"></span>{" "}
                <span className="stick stick-2"></span>{" "}
                <span className="stick stick-3"></span>{" "}
              </span>{" "}
            </button>
          </div>
          </div>
        </div>
      </div>
      <div className="hide-menu a-nav-toggle"></div>

      <div className="menu">
        <input
          type="text"
          className="form-control form-control-custom"
          placeholder="How can we help you?"
        />
        <div className="menu-main" id="accordion">
          <ul>
            {menudat && menudat?.map((ev, index) => (
              <>
                {ev?.submenus.length === 0 ?
                  <li>
                    <Link to={ev?.link}
                      onClick={() => handleMainMenuGA(ev?.name)}
                    >
                      {ev?.name}
                    </Link>
                  </li>
                  : <li class="expand">
                    <a
                      data-text=""
                      data-toggle="collapse"
                      href="#menuWhatwedo"
                      role="button"
                      aria-expanded="false"
                      aria-controls="menuWhatwedo"
                      onClick={() => handleMenuToggles(index)}
                    >
                      {ev?.name}
                    </a>
                    <div
                      class={menutoggles[index] ? "collapse" : ""}
                      id="menuWhatwedo"
                      data-parent="#menuWhatwedo"
                    >
                      <ul>
                        {ev?.submenus.map((tg) => (
                          <li>
                            <Link
                              class="animsition-link"
                              data-animsition-out-class="fade-out"
                              data-text=""
                              to={tg?.link}
                              onClick={() => handleMainMenuGA(tg?.name)}
                            >
                              {tg?.name}
                            </Link>
                          </li>
                        ))}

                      </ul>
                    </div>
                  </li>
                }
              </>
            ))}
          </ul>
        </div>

        <div className="menu-footer">
          <ul className="social social-rounded">
            <li>
              <a
                // href="https://www.facebook.com/mannaitravelqatar"
                href={socialmediadat?.facebook}
                onClick={() => handleSocialMediaGA("Facebook")}
              >
                <i className="socicon-facebook"></i>
              </a>
            </li>
            <li>
              <a
                // href="https://www.instagram.com/mannaitravelqatar/"
                href={socialmediadat?.instagram}
                onClick={() => handleSocialMediaGA("Instagram")}
              >
                <i className="socicon-instagram"></i>
              </a>
            </li>
            <li>
              <a
                // href="https://twitter.com/MannaiTravel"
                href={socialmediadat?.x}
                onClick={() => handleSocialMediaGA("Twitter")}
              >
                <i className="socicon-twitter"></i>
              </a>
            </li>
            <li>
              <a
                // href="https://www.linkedin.com/company/83501210/admin/feed/posts/"
                href={socialmediadat?.linkedin}
                onClick={() => handleSocialMediaGA("LinkedIn")}
              >
                <i className="socicon-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                // href="https://www.snapchat.com/add/mannaitravelqa"
                href={socialmediadat?.snapchat}
                onClick={() => handleSocialMediaGA("Snapchat")}
              >
                <i className="socicon-snapchat"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@mannaitravelqatar"
                target="_blank"
              >
                <img src={youtube} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@mannaitravel"
                target="_blank"
              >
                <img src={tiktok} width="28" height="28" />
              </a>
            </li>
          </ul>
        </div>

        <div className="menu-lang d-flex justify-content-end  ">
          {" "}
          <Link
            to="/privacypolicy"
            className="menu-lang-item text-decoration-none"
            onClick={() => handleHyperLinksGA("Privacy Policy")}
          >
            Privacy Policy
          </Link>{" "}
          <Link
            to="/termsandconditions"
            className="menu-lang-item text-decoration-none"
            onClick={() => handleHyperLinksGA("Terms & Conditions")}
          >
            Terms &amp; Conditions
          </Link>{" "}
          <Link
            to="/cookiepolicy"
            className="menu-lang-item text-decoration-none"
            onClick={() => handleHyperLinksGA("Cookie Policy")}
          >
            Cookie Policy
          </Link>{" "}
        </div>

        <div className="menu-copyright copyright d-flex justify-content-end ">
          COPYRIGHT &copy;{CRyear} MANNAI TRAVEL DOHA QATAR
        </div>
        <div className="biglogo-fixed">
          {" "}
          <img src={biglogopng} alt="" />
        </div>
      </div>

    </header>
  );
};

export default Header;
