import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logopng from "../Pages/img/Mannai PNG logo@72x.png";
import serviceimg10 from "../Pages/img/serviceimg10.jpg";
import biglogopng from "../Pages/img/biglogobg.png";
import element2png from "../Pages/img/element2.png";
import pluselement from "../Pages/img/pluselement.png";
import uparrowelement from "../Pages/img/uparrowelement.png";
import footerimg from "../Pages/img/footerimg.png";
import facebook from "../Pages/img/mannaifacebook.png";
import instagram from "../Pages/img/mannaiinstagram.png";
import twitter from "../Pages/img/mannaitwitter.png";
import linkedin from "../Pages/img/mannailinkedin.png";
import snapchat from "../Pages/img/mannaisnapchat.png";
import youtube from "../Pages/img/mannaiyoutube2.png";
import tiktok from "../Pages/img/mannaitiktok.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/footer.css";
import "../Pages/js/script";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {}, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <footer
      id="footer"
      className="col-md-12 row position-relative m-0 overflow-hidden darkblunbg1"
    >
      <div className="logomannaifooter">
        <img src={logopng} alt="" />
      </div>

      <div>
        {/* <div className="footerlogo">
          {" "}
          <img src={biglogopng} alt="" />
        </div> */}
        {/* <div className="mt-4 ">
          {" "}
          <img src={element2png} alt="" className="ani-left-right" />
        </div> */}
      </div>
      {/* <div className="pluselementfooter wow rotateIn">
        {" "}
        <img src={pluselement} alt="" />
      </div> */}
      {/* <div className="pluselementfootertop">
        {" "}
        <img src={pluselement} alt="" />
      </div> */}
      {/* <div className="uparrowelementfooter">
        {" "}
        <img src={uparrowelement} alt="" className="ani-top-bottom" />
      </div> */}
      <div
        className="wow fadeInLeft  col-md-5  d-flex justify-content-center align-items-center footerimage"
        data-wow-duration="1000ms"
        data-wow-delay="0ms"
        style={{
          visibility: "visible",
          animationDuration: "1000ms",
          animationDelay: "0ms",
          animationName: "fadeInLeft",
        }}
      >
        {" "}
        <img src={footerimg} alt="" />{" "}
      </div>
      <div className="col-md-7 row pb-4">
        <div className="col-md-12 ">
          <div className="wow fadeInUp text-white mt-3">
            <div className="d-flex align-items-center marginleftforfooter1">
              <div className="mb-2 wow rotateIn rotatee">
                {" "}
                <img src={pluselement} alt="" />
              </div>
              <div className="marginleftforfooter">
                <h2 className="textwrap-no p-mb-3">
                  THE NEW FACE OF TRAVEL
                </h2>
                <h2 className="mb-3">GET IN TOUCH</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-10 footersub pe-4  pb-2">
                <input
                  type="text"
                  className="form-control form-control-custom"
                  placeholder="Enter your email for newsletter subscription"
                />
              </div>
              <div className="submitbtn col-lg-4  ">
                {" "}
                <a className="btn btn-primary mr-4 fr submitbtn" href="#">
                  SUBMIT <i className="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 footer-content-flash ">
          <div className="col-lg-3 col-6  mb-3 wow fadeInUp ">
            <h5 className="SubHeading">COMPANY </h5>
            <ul className="footer-nav">
              <Link
                to="/Whoweare"
                className="text-decoration-none text-white"
                onClick={handleScrollToTop}
              >
                <li>WHO WE ARE </li>
              </Link>
              <Link
                to="/Services"
                className="text-decoration-none text-white"
                onClick={handleScrollToTop}
              >
                <li>WHAT WE DO </li>
              </Link>
              {/* <Link
                to="/Career"
                className="text-decoration-none text-white"
                onClick={handleScrollToTop}
              >
                <li>CAREERS</li>
              </Link> */}
              <Link
                to="/connectus"
                className="text-decoration-none text-white"
                onClick={handleScrollToTop}
              >
                <li>CONTACT US </li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-3 col-6  mb-3 wow fadeInUp">
            <h5 className="SubHeading">LEGAL</h5>
            <ul className="footer-nav">
              <Link
                to="/privacypolicy"
                className="text-decoration-none text-white"
                onClick={handleScrollToTop}
              >
                <li>PRIVACY POLICY </li>
              </Link>
              <Link
                to="/termsandconditions"
                className="text-decoration-none  text-white"
                onClick={handleScrollToTop}
              >
                <li>TERMS OF USE </li>
              </Link>
              <Link
                to="/cookiepolicy"
                className="text-decoration-none  text-white"
                onClick={handleScrollToTop}
              >
                <li>COOKIE POLICY</li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-4 col-12  mb-3 wow fadeInUp">
            <h5 className="SubHeading">REACH US</h5>
            <div className="contact-item">
              {/* Address :  */}
              Building No. 72,
              <br />
              Zone No. 57
              <br />
              East Industrial Street,
              <br />
              PO Box 76, Doha, Qatar
              <br />
              {/* Phone :{" "} */}
              <a className="text-decoration-none" href="tel:+97444282655">
                +974 – 4428 2655
              </a>
              <br />
              {/* Email :{" "} */}
              {/* <div style={{width: "10rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",}}> */}
              <a
                className="text-decoration-none"
                href="mailto:travel.services@mannai.com.qa"
              >
                {" "}
                travel.services@mannai.com.qa{" "}
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div>
          <ul className="footersocial social-rounded text-white d-flex">
            <li>
              <a
                href="https://www.facebook.com/mannaitravelqatar"
                target="_blank"
              >
                <img src={facebook} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mannaitravelqatar/"
                target="_blank"
              >
                <img src={instagram} width="28" height="28" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MannaiTravel" target="_blank">
                <img src={twitter} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/83501210/admin/feed/posts/"
                target="_blank"
              >
                <img src={linkedin} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.snapchat.com/add/mannaitravelqa"
                target="_blank"
              >
                <img src={snapchat} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@mannaitravelqatar"
                target="_blank"
              >
                <img src={youtube} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@mannaitravel"
                target="_blank"
              >
                <img src={tiktok} width="28" height="28" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footercopyright text-center wow fadeInUp mt-2 SubHeading">
          {" "}
          COPYRIGHT &copy;2024 MANNAI TRAVEL DOHA QATAR
        </div>
      </div>
    </footer>
  );
};

export default Footer;
