import React, { useEffect } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";

import uparrowelement from "../img/uparrowelement.png";

import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
import MannaiBanner from "../img/HOLIDAYS.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../js/timeline";
import "../js/script";
import ReactGA from "react-ga4";

const MannaiHolidayHeroSection = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleonclickGA = () => {
    ReactGA.event({
      category: "READ MORE",
      action: "Click",
      label: "READ MORE",
      value: "READ MORE"
    });
  }

  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start ">
            <ReactTitle title="Mannai Holiday | Mannai Travel" />
            <div className="slide-container">
              <div className="slide-bg wow pulse">
                <div className="inside opacityBG ">
                  <img
                    src={MannaiBanner}
                    alt=""
                    className="img-fluid object-fit-cover "
                  />
                </div>
              </div>
              <div className="container">
                {/* <div className="downplus wow rotateIn ">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>

                <div className="smlogoglobal">
                  {" "}
                  <img
                    src={biglogoservicepng}
                    alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                  />
                </div>
                <div className="downanimationelement">
                  <img
                    src={element1png}
                    alt=""
                    className="wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  />
                </div>
                <div className="uparrowelementall">
                  {" "}
                  <img src={uparrowelement} alt="" className="ani-top-bottom" />
                </div>
                <div className="roundanimation">
                  <div className="roundtxtanimationplus">
                    {" "}
                    <img src={roundtxtpng} alt="" />
                  </div>
                  <div className="roundtextanimation">
                    {" "}
                    <img src={mannairoundtxt} alt="" />{" "}
                  </div>
                </div> */}
                <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                  <div className="">
                    <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                      MANNAI HOLIDAYS
                    </h3>
                    <p
                      className=" wow slideInRight"
                      data-wow-delay=".1s"
                      data-wow-duration=".80s"
                    >
                      {" "}
                      Mannai Holidays is a luxury and retail travel and
                      destination management company under Mannai Travel. As a
                      result, we have become a one-stop shop that caters to all
                      the travel needs of an individual.{" "}
                    </p>
                    <div className="readbutton">
                      {/* <div className="leftarrowanimationelement">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div> */}

                      <a className="btn btn-primary   " href="#MannaiHoliday" onClick={() => handleonclickGA()}>
                        READ MORE{" "}
                        <i className="icon ion-ios-arrow-thin-down"></i>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MannaiHolidayHeroSection;
