import React, { useEffect } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import element2png from "../img/element2.png";
import pluselement from "../img/pluselementaward.png";

import CamelBanner from "../img/CAMELS .jpeg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";
import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/mannaiholiday.css";
import "../js/timeline";
import "../js/script";
import ReactGA from "react-ga4";

const MannaiHolidaySection3 = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleonclickGA = () => {
    ReactGA.event({
      category: "Page Redirection",
      action: "Click",
      label: "Saudi Tourism Website",
      value: "https://www.sta.gov.sa/en/home"
    });
  }

  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="Mannai Holiday | Mannai Travel" />
            <div className="slide-container p-0">
              <div className="slide-bg wow pulse">
                <div className="inside opacityBG ">
                  <img src={CamelBanner} alt="" className=" img-fluid " />
                </div>
              </div>
              <div className="container-fluid">
                {/* <div className="mannaiholidaysmlogo">
                  {" "}
                  <img src={biglogoservicepng} alt="" className="img-fluid" />
                </div> */}
                <div className="d-flex h-100 flex-lg-row flex-column">
                  <div className="d-flex justify-content-center align-items-center col-lg-6 col-md-12 col-12 p-lg-4 mt-lg-4 mt-2 p-2">
                    <div
                      className="mannaiholidaypluselement wow rotateIn"
                      style={{
                        visibility: "visible",
                        animationName: "rotateIn",
                      }}
                    >
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div className="mannaiholidayheader">
                      <h4 className="text-white font-normal">SAUDI ARABIA </h4>
                      <h4 className="text-white font-normal">
                        TOURISM AUTHORITY
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex  col-lg-6 col-md-12 col-12 p-lg-5 p-2">
                    <div>
                      <p
                        className="mannaiholidaytextp p-lg-4 p-2 wow slideInRight"
                        data-wow-delay=".1s"
                        data-wow-duration=".80s"
                      >
                        To further leverage the strong potential of the Qatar
                        market and growing demand for new destinations, we take
                        immense pride in partnering with Saudi Tourism for joint
                        destination marketing. This partnership encompasses both
                        travel entities focusing on product development, content
                        curation, and joint destination marketing across social
                        media platforms and offline marketing. The ideology
                        focuses on building awareness for Saudi Arabia as a
                        travel destination in the high-potential Qatar market,
                        creating visibility, and inspiring demand. Our product
                        portfolio extends to ready-to-book holidays and
                        personalized programs across value tours, affordable
                        luxury, and premium holidays.
                      </p>
                      <div>
                        {/* <div className="mb-2">
                          {" "}
                          <img
                            src={element2png}
                            alt=""
                            className="ani-left-right"
                          />
                        </div> */}

                        <a
                          className="btn btn-primary"
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.sta.gov.sa/en/home."
                          onClick={() => handleonclickGA()}
                        >
                          VISIT SAUDI TOURISIM WEBSITE{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
};

export default MannaiHolidaySection3;
