import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import emailjs from "@emailjs/browser";

import WOW from "wow.js";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";
import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/js/script";
import "../Pages/css/globarpartner.css";

import "../Pages/css/CareerDetail.css";
import ReactGA from "react-ga4";
import FormService from "../Pages/FormServices/FormService";

const Requestform = ({ invoker }) => {
  const location = useLocation();
  const modRef = useRef(null);
  const [modContent, setmodContent] = useState('');
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    WorkEmail: "",
    PhoneNumber: "",
    Country: "",
    POBOX: "",
    Designation: "",
    HowDidYouHearAboutUs: "",
    NumberOfUsersRequired: "",
    check: false,
  });
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegexPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
    const emailRegex = new RegExp(emailRegexPattern);

    ReactGA.event({
      category: "Form Submission",
      action: "Click",
      label: invoker,
      value: invoker,
    });

    if (emailRegex.test(formData?.WorkEmail)) {
      const dat = {
        formname: invoker,
        fname: formData.FirstName,
        lname: formData.LastName,
        companyname: formData.CompanyName,
        workemail: formData.WorkEmail,
        phno: formData.PhoneNumber,
        country: formData.Country,
        postalcode: formData.POBOX,
        designation: formData.Designation,
        howdidyouhear: formData.HowDidYouHearAboutUs,
        noofusersrequired: formData.NumberOfUsersRequired
      };

      setmodContent(`Thank you for contacting Mannai Travels. You'll soon a receive an email from us.`);
      modRef.current.click();

      FormService
        .addformcommon(dat)
        .then(setFormData({ FirstName: "", LastName: "", CompanyName: "", WorkEmail: "", PhoneNumber: "", Country: "", POBOX: "", Designation: "", HowDidYouHearAboutUs: "", NumberOfUsersRequired: "", check: false }))
        .catch((err) => console.error("Error adding FormCommon: ", err));

    } else {
      setmodContent("Please Enter a valid email address");
      modRef.current.click();
    }
  };

  return (
    <>
      <div className=" main-body theme-orange dark-horizontal globalpartnerform overflow-hidden">

        {/*DO NOT REMOVE  --Customized Model Hidden Button */}
        <button hidden type="button" class="btn btn-primary"
          ref={modRef}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"></button>

        {/*DO NOT REMOVE --Customized Model Based Alert */}
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body d-flex justify-content-between align-items-center">
                <div>{modContent}</div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>
        </div>

        <div className="animsition">
          <div className="wrapper parallax-start">
            {/* <ReactTitle title="GlobalPartner | Mannai Travel" /> */}
            <div className="section  p-0 ">
              <div className="section-item blunbgglobal">
                <div
                  className="container-fluid  wow fadeInUp "
                  data-wow-delay=".2s"
                >
                  <div className=" globalformpart ">
                    <div className="col-lg-12  ">
                      <h4 className="wow fadeInUp">
                        ALMOST THERE TO GET YOUR DEMO ACCOUNT !{" "}
                      </h4>
                      <p
                        className="globalnormaltxt wow fadeInUp"
                        data-wow-delay="1s"
                        data-wow-duration="1.2s"
                      >
                        Fill the form below to get connected with our expert{" "}
                        <br />
                        to experience the Live Demo.
                      </p>

                      <form
                        onSubmit={handleSubmit}
                        className="text-left wow fadeInUp"
                      >
                        <div className="row justify-content-center gap-2">
                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="First Name*"
                              name="FirstName"
                              value={formData.FirstName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Last Name*"
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Company Name*"
                              name="CompanyName"
                              value={formData.CompanyName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Work Email*"
                              name="WorkEmail"
                              value={formData.WorkEmail}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Phone Number*"
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Country(Qatar)"
                              name="Country"
                              value={formData.Country}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="P.O Box / Postal Code"
                              name="POBOX"
                              value={formData.POBOX}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5  p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Designation"
                              name="Designation"
                              value={formData.Designation}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="How did you hear about us ?"
                              name="HowDidYouHearAboutUs"
                              value={formData.HowDidYouHearAboutUs}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Number of User’s Required "
                              name="NumberOfUsersRequired"
                              value={formData.NumberOfUsersRequired}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row mt-3 tabcheck">
                          <div className="checkbox wow fadeInUp col-1 p-0 d-flex justify-content-center">
                            <input
                              type="checkbox"
                              className="largerCheckbox "
                              name="check"
                              onChange={handleChange}
                              checked={formData.check}
                              required
                            />
                            <span className="checkmark"></span>{" "}
                          </div>
                          <p className="formtext wow fadeInUp col-11">
                            {" "}
                            By filling and submitting this form you understand
                            and agree that the use of Mannai Travel web site is
                            subject to the mannaitravel.com{" "}
                            <span>Terms &amp; Conditions.</span> Additional
                            details regarding data collection and use of your
                            personal information, including information about
                            access, retention, rectification, deletion,
                            security, cross-border transfers and other topics,
                            is available in the mannaitravel.com{" "}
                            <span>Privacy Policy.</span>
                          </p>
                        </div>
                        <button
                          className="btn btn-primary my-2  formbtn wow fadeInLeft"
                          data-wow-delay="1s"
                          data-wow-duration="1.2s"
                          type="submit"
                        >
                          SUBMIT{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>{" "}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
};

export default Requestform;
