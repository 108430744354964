import React, { useEffect, useState } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import lufthansaicon from "../img/MannaiLogos/MannaiLogos_LCCspacetravel.png";
import flight from "../img/MannaiLogos/MannaiLogos_SpaceTravel.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import HeroSection from "./HeroSection";
import SpaceTravelsub from "./SpaceTravelsub";
import GSAForm from "./GSAForm";
import SpaceLufthansa from "./SpaceLufthansa";
import ReactGA from "react-ga4";
import ComponentServices from "../../Components/ComponentServices";

const SpaceTravel = () => {
  const [metatitle, setmetatitle] = useState("Space Travel | Mannai Travel")
  const [selected, setSelected] = useState("Space Travel");

  useEffect(() => {
    ComponentServices
      .getseotags()
      .then((ev) => {
        setmetatitle(ev?.data?.spacetravel && ev?.data?.spacetravel?.title ? ev?.data?.spacetravel?.title : "Space Travel | Mannai Travel");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: ev?.data?.spacetravel && ev?.data?.spacetravel?.title ? ev?.data?.spacetravel?.title : "Space Travel | Mannai Travel" });
      })
      .catch((err) => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Space Travel | Mannai Travel" });
        console.error("Error fetching SEO data: ", err);
      });
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleSpaceTravelToggle = (pe) => {
    setSelected(pe);
    handleonclickGA(pe);
  };

  const handleonclickGA = (de) => {
    ReactGA.event({
      category: de === "Space Travel" || "Space Cargo" ? "Content Change on Click" : "Site Redirection",
      action: "Click",
      label: de,
      value: de
    });
  };

  return (
    <div className="main-body theme-orange dark-horizontal spacetravel overflow-hidden">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title={metatitle} />
          <Header pageType={"SpaceTravel"} />
          <HeroSection data={"SPACE TRAVEL"} />
          <div id="luftansa">
            <div className="blunbg1" id="SPACE TRAVEL">
              <div className="second-page-wrapper">
                <div className="second-page-top d-flex  fs-4 fw-bold text-white h-100">
                  <div
                    role="button"
                    className={`cargo col-md-6  p-2 ${selected === "Space Travel" ? "" : "darkblunbg1"
                      }`}
                    onClick={() => handleSpaceTravelToggle("Space Travel")}
                  >
                    <div
                      className={`travel d-flex justify-content-around h-100 p-5 wow slideInLeft ${selected === "Space Travel" ? "border border-light" : ""
                        }`}
                    >
                      {/* <span>
                        SPACE TRAVEL */}
                        <img
                          src={flight}
                          alt=""
                          style={{ width: "100%" }}
                          className="mx-2"
                        />
                      {/* </span> */}
                    </div>
                  </div>
                  <div
                    role="button"
                    className={`cargo col-md-6  ${selected === "Space Cargo" ? "p-2" : "darkblunbg1 p-3"
                      }`}
                    onClick={() => handleSpaceTravelToggle("Space Cargo")}
                  >
                    <div
                      className={`h-100 d-flex justify-content-around align-items-center text-center p-4 wow slideInRight ${selected === "Space Cargo" ? "border border-light" : ""
                        }`}
                    >
                      {/* <div className="d-flex">
                        <div>
                          LUFTHANSA CITY CENTER
                          <br />
                          SPACE TRAVEL
                        </div> */}

                        <img
                          src={lufthansaicon}
                          alt=""
                          style={{ width: "100%" }}
                          className="mx-md-3 mx-0"
                        />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                {selected === "Space Travel" ? (
                  <>
                    <div id="luftansa">
                      <SpaceTravelsub />
                    </div>
                  </>
                ) : (
                  <>
                    <SpaceLufthansa />
                  </>
                )}
                <GSAForm invoker={`Space Travel`} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SpaceTravel;
