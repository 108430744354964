import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/js/script";

import pluselementaward from "../Pages/img/pluselementaward.png";
import uparrowelement from "../Pages/img/uparrowelement.png";
import c1 from "../Pages/img/vfslogo.png";
import c3 from "../Pages/img/RailEuropenew.png";
import c4 from "../Pages/img/BCDTravelnew.png";
import c5 from "../Pages/img/Thomascookwithoutbg.png";
import c6 from "../Pages/img/SOTC1new.png";
import c7 from "../Pages/img/flexiblelogo.png";

const Partners = () => {
  return (
    <div
      class="partners whoweare  container-fluid split-background"
      style={{ overflow: "hidden" }}
    >
      <div class=" ">
        <div className="row justify-content-between forheight">
          <div class="partnerpluselement wow rotateIn">
            {" "}
            <img src={pluselementaward} alt="" />
          </div>
          <div className="col-12 col-lg-5 col-md-5 left-half ">
            <div class="contentpartpartner">
              <div className="d-flex justify-content-end align-items-center">
                <img
                  src={pluselementaward}
                  alt=""
                  style={{ marginRight: "-60px" }}
                />
                <h3 class="wow slideInLeft" data-wow-duration="1.2s">
                  PARTNERING WITH
                  <br />
                  THE BEST
                </h3>
              </div>
              <p
                class="normaltext  wow slideInLeft fs-5 py-3"
                data-wow-delay=".1s"
                data-wow-duration=".80s"
              >
                {" "}
                With exclusive global partnerships with some of the world’s
                biggest names in travel, Mannai Travel offers businesses and
                individual customers travel experiences that are seamless,
                satisfying, and best-in-class. Through these partnerships, we
                cover hotel bookings, visa services, train travel, experiential
                travel, personal car rentals, luxury cruises, mice, etc.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-5 col-md-5 right-half">
            {" "}
            <div class="rightpartpartner">
              <p
                class="normaltext  wow slideInRight mb-5"
                data-wow-delay=".1s"
                data-wow-duration=".80s"
              >
                {" "}
                OUR PARTNERS
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <h3 class="wow slideInRight" data-wow-duration="1.2s">
                  {" "}
                  PARTNER WITH US <br />
                  AND BE A PART <br />
                  OF RAPIDLY GROWING <br />
                  TRAVEL GROUP IN QATAR.
                  <br />
                </h3>
                {" "}
                {/* <img src={uparrowelement} alt="" class="ani-top-bottom" /> */}
              </div>
            </div>
            <div class="row no-gutters partner-list">
              <div class="col partner-item">
                <a href="#" class="inside">
                  <img src={c1} alt="" />
                </a>
              </div>
              <div class="col partner-item partner-item1">
                <a href="#" class="inside">
                  <img src={c3} alt="" />
                </a>
              </div>
              <div class="col partner-item ">
                <a href="#" class="inside">
                  <img src={c4} alt="" />
                </a>
              </div>
              <div class="col partner-item partner-item1">
                <a href="#" class="inside">
                  <img src={c5} alt="" />
                </a>
              </div>
              <div class="col partner-item">
                <a href="#" class="inside">
                  <img src={c6} alt="" />
                </a>
              </div>
              <div class="col partner-item partner-item1">
                <a href="#" class="inside">
                  <img src={c7} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
